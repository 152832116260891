
const deleteTeamMember = async (id) => {
    const data = { id }
    const url = `https://sweetmamallama.com/php/deleteTeamMember.php`

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        // .then(res => console.log(res))
        .catch((e) => console.log('Error deleting', data, e.message))

    return true
}

export default deleteTeamMember