
const saveHours = async (data) => {
    const url = 'https://sweetmamallama.com/php/postHours.php'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .catch((e) => console.log('Error saving Hours: ', e.message))

    return true
}

export default saveHours