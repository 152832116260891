import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import banner from '../assets/banner.png'
import bannerInverted from '../assets/bannerInverted.png'
import HoursComp from '../components/HoursComp'
import Carousel from '../components/Carousel'
import { PageLoader } from '../components/Loader'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/Home.css'

import { addApos } from '../utils/regexInput'
import TeamComp from '../components/TeamComp'

const fetcher = (url) => fetch(url).then((res) => res.json())
const hoursUrl = 'https://sweetmamallama.com/php/getHours.php'
const homeUrl = 'https://sweetmamallama.com/php/getHome.php'
const teamUrl = 'https://sweetmamallama.com/php/getTeam.php'

// let count = 0
const Home = (props) => {
    // console.log(count++)

    const [mode, setMode] = useState('')
    const [images, setImages] = useState([])

    useEffect(() => {
        if (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            setMode('dark')
        } else {
            setMode('light')
        }

        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (event) => {
                const newColorScheme = event.matches ? 'dark' : 'light'
                setMode(newColorScheme)
            })
    }, [])

    const source = mode === 'light' ? banner : bannerInverted

    const { data: hoursData, error: hoursError, isLoading: hoursIsLoading } = useSWR(hoursUrl, fetcher)
    const { data: homeData, error: homeError, isLoading: homeIsLoading } = useSWR(homeUrl, fetcher)
    const { data: teamData, error: teamError, isLoading: teamIsLoading } = useSWR(teamUrl, fetcher)

    useEffect(() => {
        if (homeData && homeData[0] && homeData[0].homeImages) {
            let temp = homeData[0].homeImages.split(',')
            temp = temp.filter((i) => i.includes('http'))
            setImages(temp)
        }
    }, [homeData])

    if (hoursError || homeError || teamError) return (
        <div>
            <h1>Error.</h1>
            <h3 style={{ marginLeft: 8 }}>Please refresh the page.</h3>
        </div>
    )
    if (hoursIsLoading || homeIsLoading || teamIsLoading) return <PageLoader />

    const callToAction = addApos(homeData[0].callToAction)
    const skills = addApos(homeData[0].skills)
    const skillsTitle = addApos(homeData[0].skillsTitle)
    const innovation = addApos(homeData[0].innovation)
    const innovationTitle = addApos(homeData[0].innovationTitle)
    const customerService = addApos(homeData[0].customerService)
    const customerServiceTitle = addApos(homeData[0].customerServiceTitle)

    return (
        <div className="home">
            <MetaDecorator />

            <div>
                <img
                    src={source}
                    style={{
                        marginBottom: 20,
                    }}
                    alt="Sweet Mama Llama"
                />
                <h1>Sweet Mama Llama</h1>
            </div>

            <p style={styles.callToAction}
                dangerouslySetInnerHTML={{
                    __html: callToAction,
                }}
            ></p>

            <div style={styles.buttonContainer}>
                <a href='#about'
                    style={{
                        color: '#ea1980',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,
                    }}
                >
                    <button style={styles.button}>Our Company</button>
                </a>

                <a href='#team'
                    style={{
                        color: '#ea1980',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,

                    }}
                >
                    <button style={styles.button}>Our Team</button>
                </a>

                <Link
                    to="/products"
                    style={{
                        color: '#ea1980',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,

                    }}
                >
                    <button style={styles.button}>Our Products</button>
                </Link>

                <Link
                    to="/contact"
                    style={{
                        color: '#ea1980',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,
                        width: 170,
                    }}
                >
                    <button style={styles.button}>Contact us!</button>
                </Link>
            </div>

            <Carousel images={images} />

            <h1 id='about' style={styles.heading}>{skillsTitle}</h1>

            <p dangerouslySetInnerHTML={{ __html: skills }}></p>

            <h1 style={styles.heading}>{innovationTitle}</h1>

            <p dangerouslySetInnerHTML={{ __html: innovation }}></p>

            <h1 style={styles.heading}>{customerServiceTitle}</h1>

            <p dangerouslySetInnerHTML={{ __html: customerService }}></p>

            <TeamComp teamData={teamData} />

            <HoursComp hoursData={hoursData} />

        </div >
    )
}

const styles = {
    contact: {
        fontSize: 20,
    },
    callToAction: {
        fontSize: 18,
        fontStyle: 'italic',
        marginBottom: 30,
    },
    heading: {
        fontSize: isMobile ? 50 : 60,
        marginTop: 80,
        // textAlign: 'left'
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: isMobile && 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: isMobile ? 170 : '',
        fontSize: isMobile ? 17 : '',
        marginBottom: isMobile && 8,
    }
}

export default Home
