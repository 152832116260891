import Header from './Header'
import Footer from './Footer'
import '../styles/Layout.css'

const Layout = ({ children }) => {
    return (
        <div className="layout_container">
            <div className="inner_wrapper">
                <Header />
                <main className="content">{children}</main>
            </div>
            <Footer />
        </div>
    )
}

export default Layout