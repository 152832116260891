import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { BsGear } from 'react-icons/bs'
import { isMobile } from 'react-device-detect'
import { AiOutlineFacebook } from "react-icons/ai"
import { PageLoader } from './Loader'
import '../styles/Footer.css'
import { addApos } from '../utils/regexInput'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://sweetmamallama.com/php/getAddress.php'

const Footer = () => {
    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error) return <h2>Error. Refresh the page.</h2>
    if (isLoading) return <PageLoader />

    const currentYear = new Date().getFullYear().toString()
    const startYear = `2022`
    const copyright =
        currentYear === startYear ? startYear : `${startYear}-${currentYear}`

    return (
        <footer>
            <div className="inner">

                <div className='address'>
                    <p>{addApos(data[0].companyName)}</p>
                    <p>{addApos(data[0].location)}</p>
                    <a href={`tel:${data[0].phone}`}>{data[0].phone}</a>

                    <div style={styles.social}>
                        <a
                            href='https://www.facebook.com/profile.php?id=100089643723016&mibextid=ZbWKwL'
                            alt='facebook'
                            title='Facebook'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <AiOutlineFacebook size={32} style={styles.socialBtn} />
                        </a>
                    </div>
                </div>

                <div className="footer__content">
                    <Link to="/admin" title="Admin">
                        <BsGear color="#6a6767" size="25px" className='footer_gear' />
                        <div style={{ width: isMobile ? 0 : 95 }}></div>
                    </Link>

                    <p title="Go to akaDesignTech.com">
                        &copy; {copyright} by{' '}
                        <a
                            href="http://akaDesignTech.com"
                            rel="noreferrer"
                            target="_blank"
                            className='footer_link'
                        >
                            DesignTech
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

const styles = {
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    socialBtn: {
        marginTop: 15,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: -10,
    },
}

export default Footer
