import { useState } from 'react'
import { addApos } from '../utils/regexInput'
import { isMobile } from 'react-device-detect'
import defaultImage from '../assets/logo192.png'

const ProductItem = (props) => {
    const { item } = props

    if (!item) return null

    return (
        <div style={styles.container}>

            <h4
                style={styles.heading}
            >{addApos(item.title)}</h4>

            <div style={styles.info}>
                <img
                    src={item.image ? item.image : defaultImage}
                    alt={item.title}
                    style={styles.image}
                />

                <div style={styles.descPrice}>
                    <p style={styles.description}
                        dangerouslySetInnerHTML={{
                            __html: addApos(item.description),
                        }}
                    ></p>

                    <p style={styles.price}
                        dangerouslySetInnerHTML={{
                            __html: addApos(item.price),
                        }}
                    ></p>
                </div>
            </div>

            <a
                style={styles.link}
                href={`mailto:${process.env.REACT_APP_EMAIL}?subject=${item.title}&body=Hi Sweet Mama,%0D%0A%0D%0AI'd like to purchase the product ${item.title} please.%0D%0A%0D%0AThanks! You're the best! %0D%0A%0D%0A`}>
                <div style={styles.buyButton}>BUY NOW</div>
            </a>

        </div>
    )
}

const styles = {
    container: {
        marginBottom: 50,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '1px 2px 13px rgba(0,0,0,0.5)'
    },
    heading: {
        fontSize: isMobile ? 25 : 35,
        backgroundColor: '#ea1980',
        padding: '5px 0px 10px 20px',
        color: 'whitesmoke',
        margin: 0,
    },
    buyButton: {
        backgroundColor: '#ea1980',
        padding: '8px 20px 10px 20px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    link: {
        fontSize: 18,
        color: 'whitesmoke',
        textDecorationLine: 'none',
    },
    description: {
        lineHeight: 1.75,
    },
    price: {
        paddingTop: 20,
        fontStyle: 'italic',
        textAlign: 'right',
        fontWeight: 'bold',
    },
    image: {
        width: isMobile ? '100%' : '40%',
        objectFit: 'cover',
        float: 'left',
    },
    descPrice: {
        width: isMobile ? '100%' : '60%',
        padding: 30,
    },
    info: {
        borderRight: '1px solid gray',
        borderLeft: '1px solid gray',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
    },
}

export default ProductItem