import { Helmet } from 'react-helmet'

const MetaDecorator = (props) => {
    const title = props.title
        ? props.title
        : 'Sweet Mama Llama'

    const description = props.description
        ? props.description
        : 'The best bakery on planet earth!'

    const noscript = props.noscript
        ? props.noscript
        : 'Welcome to Sweet Mama LLama! You need to enable JavaScript to visit this site.'

    const keywords = props.keywords
        ? props.keywords
        : "Sweet Mama Llama, sweetmamallama, sweetmamallama.com, Union, Union MO, Union Missouri, Missouri, Gerald, Washington, Saint Louis, St Louis, cakes, cookies, macaroons, brownies, bakery, baked goods, candy, parties, catering, pies "

    const image = props.image ? props.image : "https://sweetmamallama.com/bannerEmail.png"

    const imageAlt = props.imageAlt ? props.imageAlt : 'Sweet Mama Llama'

    const url = props.url ? props.url : window.location.href

    const siteName = props.siteName ? props.siteName : 'Sweet Mama Llama'

    const jsonData = [
        {
            "@context": "http://schema.org",
            "@type": "SoftwareApplication",
            "name": "Sweet Mama Llama",
            "image": "https://sweetmamallama.com/bannerEmail.png",
            "url": "https://sweetmamallama.com/",
            "downloadUrl": "https://sweetmamallama.com/",
            "author": {
                "@type": "Person",
                "name": "sweetmamallama.com"
            }
        },
        {
            "@context": "http://schema.org",
            "@type": "SoftwareApplication",
            "name": "Products",
            "image": "https://sweetmamallama.com/bannerEmail.png",
            "url": "https://sweetmamallama.com/products",
            "downloadUrl": "https://sweetmamallama.com/",
            "author": {
                "@type": "Person",
                "name": "sweetmamallama.com"
            }
        },
        {
            "@context": "http://schema.org",
            "@type": "SoftwareApplication",
            "name": "Contact Sweet Mama Llama",
            "image": "https://sweetmamallama.com/bannerEmail.png",
            "url": "https://sweetmamallama.com/contact",
            "downloadUrl": "https://sweetmamallama.com/",
            "author": {
                "@type": "Person",
                "name": "sweetmamallama.com"
            }
        }
    ]

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <link
                rel="icon"
                type="image/x-icon"
                href="https://sweetmamallama.com/favicon.ico"
            />
            <link rel="apple-touch-icon" href="https://sweetmamallama.com/logo192.png" />
            <link rel="shortcut icon" href="https://sweetmamallama.com/favicon.png" />


            <noscript>{noscript}</noscript>
            <meta name="keywords" content={keywords} />
            <meta name="author" content="https://akaDesignTech.com" />

            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content={siteName} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Sweet Mama Llama" />
            <meta name="twitter:image:alt" content={imageAlt} />
            <meta name="twitter:description" content={description} />
            {/* <meta name="twitter:site" content="@powershotzz" />  */}

            {/* https://www.google.com/webmasters/markup-helper/u/0/?verticalId=4703&sourceId=107061666 */}
            <script type="application/ld+json">{`${jsonData}`}</script>
        </Helmet>
    )
}

export default MetaDecorator
