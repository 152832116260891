import { useState } from 'react'
import useSWR from 'swr'
import { PageLoader, SectionLoader } from '../components/Loader'
import ListOfProducts from './ListOfProducts'
import '../styles/forms.css'
import { addApos, removeApos } from '../utils/regexInput'
import saveProducts from '../utils/saveToDb/saveProducts'
import { isMobile } from 'react-device-detect'
import ImagePickerTeam from './ImagePickerTeam'

const fetcher = (url) => fetch(url).then((res) => res.json())
const productsUrl = 'https://sweetmamallama.com/php/getProducts.php'

const EditProducts = () => {

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [place, setPlace] = useState('')

  const { data, error, isLoading: productsIsLoading } = useSWR(productsUrl, fetcher, { refreshInterval: 2000 })

  if (productsIsLoading) return <PageLoader />
  if (error || !data) return (
    <div>
      <h1>Error.</h1>
      <h2>Server error. Refresh the page.</h2>
    </div>
  )

  const handleChangeTitle = (e) => {
    setMessage('')
    setTitle(e.target.value)
  }

  const handleChangeDescription = (e) => {
    setMessage('')
    setDescription(e.target.value)
  }

  const handleChangePrice = (e) => {
    setMessage('')
    setPrice(e.target.value)
  }

  const handleChangePlace = (e) => {
    setMessage('')
    setPlace(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const res = await saveProducts({
      title: removeApos(title),
      description: removeApos(description),
      price: removeApos(price),
      place,
      image,
    })
    setIsLoading(false)
    res && setMessage('Success!')
    setTitle('')
    setDescription('')
    setPrice('')
    setPlace('')
    setImage('')
  }

  return (
    <div style={styles.main}>
      <h2 style={styles.heading}>Add a Product.</h2>

      <div style={styles.form}>

        <div style={styles.imageDiv}>
          <p style={styles.imageLabel}>Add an image</p>
          <ImagePickerTeam image={image} setImage={setImage} />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Title</div>
          <input
            type="text"
            name="title"
            value={addApos(title)}
            className="inputField"
            onChange={handleChangeTitle}
            style={styles.input}
          />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Description</div>
          <textarea
            type="text"
            name="description"
            value={addApos(description)}
            className="inputField"
            onChange={handleChangeDescription}
            style={styles.textarea}
          />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Price</div>
          <input
            type="text"
            name="price"
            value={price}
            className="inputField"
            onChange={handleChangePrice}
            style={styles.input}
          />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Placement</div>
          <input
            type='text'
            name="place"
            value={addApos(place)}
            className="inputField"
            onChange={handleChangePlace}
            style={styles.input}
            placeholder='largest number will display first'
          />
        </div>

        <div style={styles.submit}>
          <input
            type="submit"
            value="SUBMIT"
            className="submit"
            onClick={handleSubmit}
          />
        </div>

        <p style={styles.success}>{message}</p>

        {isLoading && <SectionLoader />}
      </div>

      <ListOfProducts data={data} />
    </div>
  )
}

const styles = {
  main: {
    margin: 'auto',
  },
  form: {
    padding: isMobile ? 20 : 30,
    border: '1px solid',
    margin: isMobile ? 5 : 30,
  },
  group: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  heading: {
    textAlign: isMobile ? 'center' : 'left',
    marginTop: isMobile ? 40 : 'auto',
    marginBottom: isMobile ? 20 : 'auto',
  },
  label: {
    fontSize: 20,
    flex: 2,
  },
  success: {
    color: '#04aa6d',
    fontSize: 20,
    marginLeft: 20,
    textAlign: 'right'
  },
  input: {
    flex: 4,
  },
  textarea: {
    flex: 4,
    height: 100,
  },
  submit: {
    width: '100%',
    margin: 'auto',
    textAlign: isMobile ? 'center' : 'right',
    marginTop: 20,
  },
  imageDiv: {
    marginBottom: 40,
  },
  imageLabel: {
    fontSize: 20,
  },
}

export default EditProducts
