const HoursComp = (props) => {
    const { hoursData } = props
    const data = hoursData[0]

    return (
        <div style={styles.main}>

            <h1 style={styles.heading}>Hours.</h1>

            <div style={styles.day}>
                <div>Monday</div>
                <div>{data.Monday}</div>
            </div>

            <div style={styles.day}>
                <div>Tuesday</div>
                <div>{data.Tuesday}</div>
            </div>

            <div style={styles.day}>
                <div>Wednesday</div>
                <div>{data.Wednesday}</div>
            </div>

            <div style={styles.day}>
                <div>Thursday</div>
                <div>{data.Thursday}</div>
            </div>

            <div style={styles.day}>
                <div>Friday</div>
                <div>{data.Friday}</div>
            </div>

            <div style={styles.day}>
                <div>Saturday</div>
                <div>{data.Saturday}</div>
            </div>

            <div style={styles.day}>
                <div>Sunday</div>
                <div>{data.Sunday}</div>
            </div>
        </div>
    )
}

const styles = {
    main: {
        width: 275,
        margin: 'auto',
        marginTop: 60,
        marginBottom: 60,
    },
    day: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    heading: {
        fontSize: 60,
    }
}

export default HoursComp