import { useState } from 'react'
import useSWR from 'swr'
import { PageLoader } from '../components/Loader'
import ImagePickerHomePage from './ImagePickerHomePage'
import '../styles/forms.css'
import { addApos } from '../utils/regexInput'
import saveHome from '../utils/saveToDb/saveHome'
import { SectionLoader } from '../components/Loader'
import { isMobile } from 'react-device-detect'

const fetcher = (url) => fetch(url).then((res) => res.json())
const homeUrl = 'https://sweetmamallama.com/php/getHome.php'

const EditHome = () => {

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [allData, setAllData] = useState({
    callToAction: undefined,
    skillsTitle: undefined,
    skills: undefined,
    innovationTitle: undefined,
    innovation: undefined,
    customerServiceTitle: undefined,
    customerService: undefined,
    homeImages: undefined
  })

  const { data, error, isLoading: homeIsLoading } = useSWR(homeUrl, fetcher, { refreshInterval: 2000 })

  if (homeIsLoading) return <PageLoader />
  if (error || !data) return (
    <div>
      <h1>Error.</h1>
      <h2>Server error. Refresh the page.</h2>
    </div>
  )

  const handleChange = (e) => {
    setMessage('')
    setAllData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    const res = saveHome({
      callToAction: allData.callToAction
        ? allData.callToAction
        : data[0].callToAction,
      skillsTitle: allData.skillsTitle
        ? allData.skillsTitle
        : data[0].skillsTitle,
      skills: allData.skills
        ? allData.skills
        : data[0].skills,
      innovationTitle: allData.innovationTitle
        ? allData.innovationTitle
        : data[0].innovationTitle,
      innovation: allData.innovation
        ? allData.innovation
        : data[0].innovation,
      customerServiceTitle: allData.customerServiceTitle
        ? allData.customerServiceTitle
        : data[0].customerServiceTitle,
      customerService: allData.customerService
        ? allData.customerService
        : data[0].customerService,
      homeImages: allData.homeImages
        ? allData.homeImages
        : data[0].homeImages
    })
    setIsLoading(false)
    res && setMessage('Success!')
  }

  return (
    <div className="container">

      <div style={styles.headingButton}>
        <h2 style={styles.heading}>Edit the Home Page.</h2>
        <a href="#images" style={styles.button}>
          <button >Edit Images</button>
        </a>
      </div>

      <form className="main">

        <div className="inputGroup" style={styles.inputGroup}>
          <h3>Call To Action</h3>
          <textarea
            name="callToAction"
            value={
              allData['callToAction']
                ? addApos(allData['callToAction'])
                : addApos(data[0].callToAction)
            }
            className="textarea"
            onChange={handleChange}
          />
        </div>

        <div className="inputGroup" style={styles.inputGroup}>
          <h3>Skills Title</h3>
          <input
            name="skillsTitle"
            value={
              allData['skillsTitle']
                ? addApos(allData['skillsTitle'])
                : addApos(data[0].skillsTitle)
            }
            className="inputField"
            onChange={handleChange}
          />
        </div>

        <div className="inputGroup">
          <h3>Skills</h3>
          <textarea
            name="skills"
            value={
              allData['skills']
                ? addApos(allData['skills'])
                : addApos(data[0].skills)
            }
            className="textarea"
            onChange={handleChange}
          />
        </div>

        <div className="inputGroup" style={styles.inputGroup}>
          <h3>Innovation Title</h3>
          <input
            name="innovationTitle"
            value={
              allData['innovationTitle']
                ? addApos(allData['innovationTitle'])
                : addApos(data[0].innovationTitle)
            }
            className="inputField"
            onChange={handleChange}
          />
        </div>

        <div className="inputGroup">
          <h3>Innovation</h3>
          <textarea
            name="innovation"
            value={
              allData['innovation']
                ? addApos(allData['innovation'])
                : addApos(data[0].innovation)
            }
            className="textarea"
            onChange={handleChange}
          />
        </div>

        <div className="inputGroup" style={styles.inputGroup}>
          <h3>Customer Service Title</h3>
          <input
            name="customerServiceTitle"
            value={
              allData['customerServiceTitle']
                ? addApos(allData['customerServiceTitle'])
                : addApos(data[0].customerServiceTitle)
            }
            className="inputField"
            onChange={handleChange}
          />
        </div>

        <div className="inputGroup">
          <h3>Customer Service</h3>
          <textarea
            name="customerService"
            value={
              allData['customerService']
                ? addApos(allData['customerService'])
                : addApos(data[0].customerService)
            }
            className="textarea"
            onChange={handleChange}
          />
        </div>

        <div
          id='images'
          className="inputGroupPicker"
          style={styles.imagePicker}
        >
          <ImagePickerHomePage
            homeImages={
              allData['homeImages']
                ? allData['homeImages']
                : data[0].homeImages
            }
            setAllData={setAllData}
            name="homeImages"
          />
        </div>

        <div className="button_container">
          <input
            type="submit"
            value="SUBMIT"
            className="submit"
            style={styles.submit}
            onClick={handleSubmit}
          />
        </div>
      </form>

      <span style={styles.success}>{message}</span>

      {isLoading && <SectionLoader />}
    </div>
  )
}

const styles = {
  heading: {
    textAlign: isMobile ? 'center' : 'left',
  },
  headingButton: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: 'center',
    marginTop: 40,
  },
  button: {
    transform: 'scale(75%)',
    textAlign: 'center'
  },
  inputGroup: {
    marginTop: 60,
  },
  imagePicker: {
    marginTop: 80,
  },
  success: {
    color: '#04aa6d',
    fontSize: 20,
  },
  submit: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: 20,
  }
}

export default EditHome
