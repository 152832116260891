import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { shuffle } from '../utils/shuffle'

function Carousel(props) {
    const { images } = props

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        pauseOnHover: false,
        arrows: false,
    }

    const styles = {
        container: {
            marginTop: 40,
            marginBottom: -30,

        },
        imgStyle: {
            maxWidth: '100%',
            margin: 'auto',
        }
    }

    return (
        <div style={styles.container}>
            <Slider {...settings}>
                {images &&
                    shuffle(images).map((img) => (
                        <div key={img}>
                            <img src={img} style={styles.imgStyle} alt='car' />
                        </div>
                    ))}
            </Slider>
        </div>
    )
}

export default Carousel
