import useSWR from 'swr'
import { PageLoader } from '../components/Loader'
import ProductItem from '../components/ProductItem'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/Products.css'
import { isMobile } from 'react-device-detect'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://sweetmamallama.com/php/getProducts.php'

// let count = 0
const Products = (props) => {
    //   console.log(count++);

    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error || !data) return <div><h1>Error.</h1><h3>Server error. Please refresh the page.</h3></div>
    if (isLoading) return <PageLoader />

    return (
        <div className='about'>

            <MetaDecorator
                title="Sweet Mama Llama"
                description=" We are proud to contribute to the local economy and connect you with the best baked goods in the area."
                url="https://sweetmamallama.com/products"
            />

            <h1 style={styles.heading}>Our Products.</h1>

            <div style={styles.productItems}>
                {data.map(item => <ProductItem item={item} key={item.id} />)}
            </div>

        </div>
    )
}

const styles = {
    heading: {
        marginBottom: 50,
    },
    productItems: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        flexWrap: 'wrap',
    }
}

export default Products
