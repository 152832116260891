import { addApos } from '../utils/regexInput'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import useSWR from 'swr'
import { PageLoader } from '../components/Loader'
import deleteTeamMember from '../utils/saveToDb/deleteTeamMember'
import avatar from '../assets/avatar.png'

const fetcher = (url) => fetch(url).then((res) => res.json())
const teamUrl = 'https://sweetmamallama.com/php/getTeam.php'

const ListOfTeamMembers = (props) => {
    let navigate = useNavigate()

    const { data, error, isLoading } = useSWR(teamUrl, fetcher, { refreshInterval: 2000 })

    if (isLoading) return <PageLoader />

    if (error) return (
        <div>
            <h1>Error.</h1>
            <h2>Server error. Refresh the page.</h2>
        </div>
    )

    const deleteIt = async (e) => {
        if (window.confirm('Are you sure you want to delete this team member?') === true) {
            await deleteTeamMember(e.target.name)
        }
    }

    const editIt = (e) => navigate(`/member/${e.target.name}`)

    const renderItem = (item) => {
        return <div key={item.id} style={styles.itemContainer}>

            <img src={item.image ? item.image : avatar} style={styles.image} alt={item.name} />

            <div style={styles.headingAndButton}>

                <h3 style={styles.service}>({item.place}) {addApos(item.name)}</h3>

                <div style={styles.buttonContainer}>
                    <button
                        name={item.id}
                        style={styles.button}
                        onClick={deleteIt}
                    >Delete</button>

                    <button
                        name={item.id}
                        style={styles.button}
                        onClick={editIt}
                    >Edit</button>
                </div>
            </div>

            <p style={styles.description}
                dangerouslySetInnerHTML={{
                    __html: addApos(item.description),
                }}></p>

            <p style={styles.email}>{item.email ? `Email: ${item.email}` : ''}</p>

        </div>
    }

    return (
        <div style={styles.main}>
            <h2 style={styles.heading}>View, Edit, or Delete a Team Member.</h2>

            <div style={styles.list}>
                {data.map(item => renderItem(item))}
            </div>

        </div>
    )
}

const styles = {
    main: {
        marginTop: 60,
    },
    itemContainer: {
        border: '1px solid',
        margin: isMobile ? 0 : 30,
        padding: 30,
        borderRadius: 10,
    },
    list: {
        marginTop: 20,
    },
    image: {
        borderRadius: '50%',
        height: 150,
    },
    description: {
        lineHeight: 1.7
    },
    email: {
        marginTop: 15,
    },
    headingAndButton: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: isMobile ? 20 : 0,
        width: isMobile ? '100%' : 'auto',
        flex: 5
    },
    service: {
        flex: 5
    },
    button: {
        flex: 1,
        margin: 5,
        transform: 'scale(90%)'
    },
    heading: {
        textAlign: isMobile ? 'center' : 'left',
        marginTop: isMobile ? 40 : 'auto',
    },
}

export default ListOfTeamMembers