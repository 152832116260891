import { removeApos } from '../regexInput'

const saveProducts = async (rawData) => {
    const data = {
        id: rawData.id,
        title: removeApos(rawData.title),
        description: removeApos(rawData.description),
        price: removeApos(rawData.price),
        place: rawData.place,
        image: rawData.image,
    }

    const url = 'https://sweetmamallama.com/php/postProducts.php'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .catch((e) => console.log('Error saving Product: ', e.message))

    return true
}

export default saveProducts