import { useState } from 'react'

const LoginForm = (props) => {
    const { login } = props

    const [password, setPassword] = useState('')
    const [type, setType] = useState('password')

    return (
        <div style={styles.container}>
            <input
                type={type}
                onMouseOver={() => setType('text')}
                onMouseLeave={() => setType('password')}
                name="name"
                value={password}
                style={styles.input}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='password'
            />

            <button onClick={() => login(password)}>Submit</button>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    input: {
        fontSize: 18,
        // fontFamily: 'cursive',
        backgroundColor: 'whitesmoke',
        borderRadius: 5,
        height: 40,
        paddingLeft: 10,
        width: '80%',
        marginBottom: 10,
    },
}

export default LoginForm