import './App.css'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Products from './pages/Products'
import Contact from './pages/Contact'
import Layout from './components/Layout'
import Admin from './admin/Admin'
import EditSingleProduct from './admin/EditSingleProduct'
import EditTeamMember from './admin/EditTeamMember'

// const store = createStore(rootReducer, compose(applyMiddleware(thunk)))
//https://redux.js.org/introduction/why-rtk-is-redux-today

function App() {
  return (
    <main>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} exact />
          <Route path='/admin' element={<Admin />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/product/:id' element={<EditSingleProduct />} />
          <Route path='/member/:id' element={<EditTeamMember />} />
        </Routes>
      </Layout>
    </main>
  )
}

export default App
